import {CompositeStateType} from "contracts/holotrak/compositeState";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";




/*
    {
        "name": "altitude",
        "dataType": "number",
        "description": "",
        "attributeTags": {
            "average": "200",
            "max": "400",
            "min": "332"
        }
    }
*/

@jsonObject()
export class DeviceAttributeTags {
    @jsonMember(Number,{
        deserializer: (value) => {
            try {
                return parseFloat(value);
            } catch (e) {
                return 0;
            }
        }
    })
    avg: number;

    @jsonMember(Number,{
        deserializer: (value) => {
            try {
                return parseFloat(value);
            } catch (e) {
                return 0;
            }
        }
    })
    max: number

    @jsonMember(Number,{
        deserializer: (value) => {
            try {
                return parseFloat(value);
            } catch (e) {
                return 0;
            }
        }
    })
    min: number
}

@jsonObject()
export class DeviceAttribute {
    @jsonMember(() => String)
    name: CompositeStateType

    @jsonMember(String)
    dataType: string

    @jsonMember(String)
    description: string

    @jsonMember(String)
    value: string

    @jsonMember(DeviceAttributeTags)
    attributeTags: DeviceAttributeTags
}


@jsonObject
export class DeviceTag {
    @jsonMember(String)
    key: string
    @jsonMember(String)
    value: string
}

export const DeviceTagSerializer = new TypedJSON(DeviceTag);
export const DeviceAttributeSerializer = new TypedJSON(DeviceAttribute);
export const DeviceAttributeTagsSerializer = new TypedJSON(DeviceAttributeTags);
