import {Button, Form, Input, Space, Spin, Typography} from "antd";
import {getCompositeItemSuffix, getCompositeItemTitle} from "contracts/holotrak/_helpers/compositeItemHelpers";
import {CompositeStateType} from "contracts/holotrak/compositeState";
import {LosantCoreDevice} from "contracts/holotrak/losantCoreDevice";
import React, {useEffect, useMemo} from "react";
import {useUpdateDeviceAttributesMutation} from "redux/services/losant/devices";
import {useHolotrakAppContext} from "utils/HolotrakAppContext";

const {Title, Text} = Typography;

interface IDeviceAttributesForm {
    device: LosantCoreDevice;
}

export const DeviceAttributesForm: React.FC<IDeviceAttributesForm> = ({device}) => {
    const [form] = Form.useForm();
    const {rootMessageApi} = useHolotrakAppContext();

    const [saveAttributeTags, {isLoading: isSavingAttributeTags}] = useUpdateDeviceAttributesMutation();

    const attributesToManage: CompositeStateType[] = useMemo(() => ([
        'fill_level',
        'battery_level',
        'temperature',
        'altitude',
    ]), []);

    const onFinish = () => {
        form.validateFields()
            .then(async values => {
                const hideSavingMessage = rootMessageApi.loading("Saving", 0);

                saveAttributeTags({
                    deviceId: device.id,
                    attributes: values.items
                }).then(() => {
                    hideSavingMessage();
                    rootMessageApi.success("Device Configurations Saved Successfully.");
                }).catch(() => {
                    hideSavingMessage();
                    rootMessageApi.error("Error: Device Configurations Saved Failure.");
                });


            })
            .catch(() => {
                rootMessageApi.error("Please Check Configuration form for any errors.").then();
            })
    };

    useEffect(() => {
        if (device) {
            const savedValues = {
                items: attributesToManage.map(attribute => {
                    const [ca] = device.attributes.filter(atr => atr.name === attribute);
                    const {min = "0", max = "100", avg = "50"} = ca?.attributeTags ?? {};

                    return {
                        name: attribute,
                        min,
                        max,
                        avg
                    };
                }),
            }

            form.setFieldsValue(savedValues);
        }
    }, [attributesToManage, device, form]);

    return (
        <Spin spinning={isSavingAttributeTags}>
            <Space direction='vertical'>
                <Title level={5}>Device Configurations</Title>
                <Form
                    form={form}
                    name="device-attributes-form"
                    className="device-attributes-form"
                    layout="vertical"
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    autoComplete="off"
                    initialValues={{items: []}}
                    onFinish={onFinish}
                >
                    <Form.List name='items'>
                        {
                            (fields) => (
                                <Space direction="vertical" size="large">
                                    {
                                        fields.map((field) => {
                                            const compositeItemTitle = getCompositeItemTitle(attributesToManage[field.name]);
                                            const unit = getCompositeItemSuffix(attributesToManage[field.name]);
                                            return (
                                                <Space key={field.key} direction='vertical'>
                                                    <Text>{compositeItemTitle} - {unit}</Text>
                                                    <DeviceAttributeConfig
                                                        name={field.name}
                                                    />
                                                </Space>
                                            );
                                        })
                                    }
                                </Space>
                            )
                        }
                    </Form.List>
                </Form>
                <Button
                    form="device-attributes-form"
                    type="primary"
                    onClick={onFinish}
                    loading={isSavingAttributeTags}
                >
                    Save Configuration
                </Button>
            </Space>
        </Spin>
    );
}

const DeviceAttributeConfig: React.FC<{ name: number }> = ({name}) => {
    return (
        <Space>
            <Form.Item
                noStyle
                name={[name, "min"]}
                required
            >
                <Input
                    placeholder="Minimum Threshold"
                />
            </Form.Item>

            <Form.Item
                noStyle
                name={[name, "avg"]}
                required
            >
                <Input
                    placeholder="Average Threshold"
                />
            </Form.Item>

            <Form.Item
                noStyle
                name={[name, "max"]}
                required
            >
                <Input
                    placeholder="Maximum Threshold"
                />
            </Form.Item>
        </Space>
    )
}
