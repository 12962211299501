import {EDeviceCommandTypes} from "contracts/EDeviceCommandTypes";
import {EGeofencingLogic, getDeviceGeofencingLogic} from "contracts/EGeofencingLogic";
import {getCompositeItemSuffix, getCompositeItemTitle} from "contracts/holotrak/_helpers/compositeItemHelpers";
import {ICANDetail} from "contracts/ICANDetail";
import {IReportableItem} from "contracts/IReportableItem";
import {StateComputedValue, SupportedComputableState} from "contracts/supported-computable-state";
import dayjs from "dayjs";
import {jsonArrayMember, jsonMember, jsonObject, TypedJSON} from "typedjson";
import {EBatteryLevel} from "../EBatteryLevel";
import {EDeviceSignal, EDeviceStatus, ELosantDeviceClass, ESensorType, getSensorTypeFromString} from "../EDeviceStatus";
import {IDeviceStatus} from "../IDeviceStatus";
import {IHasCompositeState} from "../IHasCompositeState";
import {IHasAttributes, IHasTags} from "../IHasTags";
import {CompositeState, CompositeStateParser, CompositeStateType} from "./compositeState";
import {CompositeStateItem, CompositeStateItemSerializers} from "./compositeStateItem";
import {ConnectionInfo} from "./connectionInfo";
import {DeviceAttribute, DeviceAttributeSerializer, DeviceTag, DeviceTagSerializer} from "./deviceTag";
import {GeofenceSlotConfig} from "./GeofenceSlotConfig";
import {MqttMessage} from "./mqttMessage";

type SupportedDeviceModel = 'barra-gps' | 'holotrak-fill-sensor';

@jsonObject()
export class LosantCoreDevice implements IHasTags, IHasCompositeState, IReportableItem {

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    creationDate: dayjs.Dayjs;

    @jsonMember(String)
    name: string;

    @jsonMember(() => ELosantDeviceClass, {
        deserializer: (value: any) => {
            switch (value) {
                case 'gateway':
                case ELosantDeviceClass.GATEWAY:
                    return ELosantDeviceClass.GATEWAY;

                case 'floating':
                case ELosantDeviceClass.FLOATING:
                    return ELosantDeviceClass.FLOATING;

                case 'device':
                default:
                    return ELosantDeviceClass.DEVICE;
            }
        }
    })
    deviceClass: ELosantDeviceClass;

    @jsonArrayMember(DeviceTag, {
        deserializer: (value: any) => {
            return LosantCoreDevice.parseTags({tags: value});
        }
    })
    tags: DeviceTag[] | string;

    @jsonArrayMember(DeviceAttribute, {
        deserializer: (value: any) => {
            return (typeof value === 'string') ? LosantCoreDevice.parseAttributes({attributes: value}) : value;
        }
    })
    attributes: DeviceAttribute[];

    @jsonMember(String)
    applicationId: string
    @jsonMember(String)
    _etag: string
    @jsonMember(String)
    deviceId: string
    @jsonMember(String)
    id: string
    @jsonMember(ConnectionInfo)
    connectionInfo: ConnectionInfo
    @jsonMember(CompositeState)
    compositeState: CompositeState;
    @jsonMember(String)
    group: string;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    lastUpdated: dayjs.Dayjs;


    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return value && dayjs(value);
        }
    })
    createdAt: dayjs.Dayjs;

    get type(): string {
        return this.getTagValue('device_type');
    }

    get additionalInfo(): string {
        return this.getCompositeValue('address', '', '', false);
    }

    get uiHeight(): number {
        return (this.additionalInfo || this.additionalInfo === '') ? 111 : 84;
    }

    get status(): EDeviceStatus {
        if (this.sensorType === ESensorType.ALARMS) {
            return this.compositeState?.computeAlarmStatus() ?? EDeviceStatus.NEVER_CONNECTED;
        } else {
            return this.compositeState?.computeOutdoorStatus() ?? EDeviceStatus.NEVER_CONNECTED;
        }
    }

    get signalStrength(): EDeviceSignal {
        return this.compositeState.computeSignalStrength() ?? EDeviceSignal.NONE;
    }

    get statusInfo(): IDeviceStatus {
        let status = this.status;
        if (this.sensorType === ESensorType.ALARMS) {
            status = this.isAlarmArmed ? EDeviceStatus.ALARM_ARMED : EDeviceStatus.ALARM_DISARMED;
        }

        return {
            icon: 'fa fa-circle',
            updatedText: this.lastUpdatedOn?.fromNow() || '--',
            value: status
        }
    }

    get lastUpdatedOn(): dayjs.Dayjs {
        // FIXME: never connected should be never connected.
        return this.getCompositeStateItem('last_reported_at')?.updatedAt ?? this.lastUpdated ?? null;
    }

    get batteryText(): string {
        return this.getCompositeValue('battery_level', '%', 'NA', false);
    }

    get batteryLevel(): EBatteryLevel {
        return this.compositeState?.computeBatteryLevel();
    }

    get sensorType(): ESensorType {
        return getSensorTypeFromString(this.getTagValue('sensor_type'));
    }

    get isMappable(): boolean {
        return true;
    }

    get effectiveDeviceId(): string {
        return this.deviceId;
    }

    get deviceModel(): string {
        return this.getTagValue('device_model');
    }

    get isAlarmArmed(): boolean {
        return this.getCompositeBooleanValue('is_alarm_armed');
    }

    get isAttributeConfigSupported(): boolean {
        return LosantCoreDevice.getAttributeConfigSupportedModels().includes(this.deviceModel);
    }

    get isSirenSupported(): boolean {
        return LosantCoreDevice.getSirenSupportedModels().includes(this.deviceModel);
    }

    get isInternalBatterySupported(): boolean {
        return LosantCoreDevice.getBatteriesSupportedModels().includes(this.getTagValue('device_type'));
    }

    static getBatteriesSupportedModels() {
        return [
            'atrack',
            'Yabby Edge'
        ];
    }

    static getAttributeConfigSupportedModels() {
        return [
            'holotrak-fill-sensor'
        ];
    }

    static getSirenSupportedModels() {
        return [
            "queclink-gv-57-mg",
            // "queclink-gv-620-mg"
        ];
    }

    static parseTags(device: IHasTags): DeviceTag[] {
        return (typeof device.tags === 'string')
            ? DeviceTagSerializer?.parseAsArray(JSON.parse(device.tags))
            : device?.tags;
    }

    static parseAttributes(device: IHasAttributes): DeviceAttribute[] {
        return (typeof device.attributes === 'string')
            ? DeviceAttributeSerializer?.parseAsArray(JSON.parse(device.attributes))
            : device?.attributes;
    }

    /**
     * Get the value of a tag from the device tags
     *
     * using `device_type` allows getting the `device *type*` without any transformations
     *
     * for instance, `type` will return `Smart Tracker` for a device with `type` tag set to `calamp`
     *  but the original value 'calamp' is returned with `device_type`
     *  - for Yabby Edge the type itself is stored as Yabby Edge for some odd reason.
     *
     * this allows for a more consistent way of getting the device type when adding manufacturer-specific logic
     */
    getTagValue(name: string): string | null {
        if (typeof this.tags === 'string') {
            return null;
        }

        const qualifiedTagName = (name === 'device_type') ? 'type' : name;
        const value = this.tags?.find(tag => tag.key === qualifiedTagName)?.value || null;

        if (name === 'type' && value) {
            switch (`${value}`.toLowerCase()) {
                case 'calamp':
                    return 'Smart Tracker';

                case 'external-sensor':
                    return 'External';

                case 'atrack':
                    return 'ATrack';

                case 'morey':
                    return 'Morey';

                case 'queclink':
                    return 'Queclink';

                default:
                    return value;
            }
        }

        return value;
    }

    updateState(message: MqttMessage) {
        if (!this.compositeState) {
            this.compositeState = CompositeStateParser.parse({});
        }

        const stateKeys = Object.keys(this.compositeState ?? {}) as CompositeStateType[];

        stateKeys.forEach(itemKey => {
            this.compositeState[itemKey] = (message[itemKey] === undefined || message[itemKey] === null)
                ? this.compositeState[itemKey]
                : CompositeStateItemSerializers[itemKey].parse({
                    time: message.last_reported_at,
                    value: message[itemKey]
                });
        });

        if (stateKeys.includes('location') || stateKeys.push('address')) {
            this.compositeState.last_reported_at = CompositeStateItemSerializers.last_location_updated_at.parse({
                time: dayjs(),
                value: dayjs()
            });
        }
    }

    matchDeviceModel(model: string | SupportedDeviceModel) {
        return this.deviceModel?.toLowerCase() === model;
    }

    getCompositeStateItem(index: CompositeStateType): CompositeStateItem {
        return this.compositeState?.getItem(index);
    }


    // INFO: Use getCompositeBooleanValue for boolean responses
    getCompositeValue(index: CompositeStateType, suffix: string = '', fallback: string = '-', alwaysShowSuffix: boolean = true) {
        const item = this.getCompositeStateItem(index);

        if (!item?.hasValue() || this.shouldItemValueBeHidden(item, index)) {
            return (alwaysShowSuffix) ? fallback + ` ${suffix}` : fallback;
        }

        const itemValue = item.toString() + ` ${suffix}`;
        return `${itemValue}`.trim();
    }

    // FIXME: This is a hack to get the boolean value from the composite state. Because BooleanCompositeStateItem toString returns Yes/No
    getCompositeBooleanValue(index: CompositeStateType, fallback: boolean = false) {
        return this.getCompositeValue(index, '', '') === 'Yes' ?? fallback;
    }

    getCompositeCanDetail(index: CompositeStateType): ICANDetail {
        return {
            key: index,
            title: getCompositeItemTitle(index),
            value: this.getCompositeValue(index, getCompositeItemSuffix(index)),
            status: this.getCompositeItemStatus(index),
        };
    }

    getStateComputedValue(itemKey: SupportedComputableState): StateComputedValue {
        switch (itemKey) {
            case 'service_state':
                return {
                    title: 'Service State',
                    // TODO: Compute Service State
                    value: 'Okay!',
                    suffix: '',
                    type: "success"
                };


            case 'next_service':
                return {
                    title: 'Next Service',
                    // TODO: Compute Next Service based on device data and attribute configuration
                    value: '-',
                    suffix: 'Kms',
                    type: "danger"
                }

            case 'battery':
                return {
                    title: 'Battery',
                    value: this.getCompositeValue('battery_level'),
                    suffix: getCompositeItemSuffix('battery_level'),
                    type: null
                }

            case 'voltage':
                return {
                    title: 'Voltage',
                    value: this.getCompositeValue('voltage_level'),
                    suffix: getCompositeItemSuffix('voltage_level'),
                    type: null
                }

            case 'engine_hours':
                return {
                    title: 'Engine Hours',
                    value: this.getCompositeValue('engine_oil_maintenance_hours'),
                    suffix: getCompositeItemSuffix('engine_oil_maintenance_hours'),
                    type: null
                }

            case 'distance_travelled':
                return {
                    title: 'Total Distance',
                    // TODO: Compute Total Distance Travelled
                    value: '-',
                    suffix: 'miles',
                    type: null
                }

            case 'rpm':
                return {
                    title: 'Engine Speed',
                    value: this.getCompositeValue('rpm'),
                    suffix: getCompositeItemSuffix('rpm'),
                    type: null
                }

            case 'fuel_level':
                return {
                    title: 'Fuel Level',
                    // TODO: Compute Fuel Level
                    value: '-',
                    suffix: 'L',
                    type: null
                }

            default:
                return {
                    title: `Unsupported Key ${itemKey}`,
                    value: '-',
                    suffix: null,
                    type: "warning"
                };
        }
    }

    // TODO: Have better computation of the composite item status
    getCompositeItemStatus(index: CompositeStateType): EDeviceStatus {
        return this.getCompositeStateItem(index).hasValue() ? EDeviceStatus.ONLINE : EDeviceStatus.OFFLINE;
    }

    shouldItemValueBeHidden(item: CompositeStateItem, index: CompositeStateType) {
        return (this.sensorType === ESensorType.MILLER_CAN) ? !item?.hasValidMillerValue(index) : false;
    }

    getDateString(date: number | string | dayjs.Dayjs, fallback = '--'): string {
        if (date) {
            return dayjs(date).toISOString();
        }

        return fallback;
    }

    geofenceSlotSupported(slot: GeofenceSlotConfig): boolean {
        const deviceType = this.getTagValue('device_type');
        const geofencingLogic = getDeviceGeofencingLogic(this.getTagValue('geofencing_logic'));

        // FUTURE: Add support for device models
        return (geofencingLogic === EGeofencingLogic.ON_PLATFORM)
            ? true
            : (deviceType && deviceType.toLowerCase() === slot?.deviceMake.toLowerCase());
    }

    getCommand(commandType: EDeviceCommandTypes, type?: string): string | void {
        return undefined;
    }
}


export const LosantCoreDeviceSerializer = new TypedJSON(LosantCoreDevice);
